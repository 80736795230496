import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(
        filter: { extension: { regex: "/(jpg)|(jpeg)|(png)|(webp)/" }, relativeDirectory: { eq: "carousel-partners" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
              originalName
            }
          }
        }
      }
    }
  `);

  return data.placeholderImage.nodes;
};

const Items = () => {
  return Images().map((img, index) => {
    var url = "";
    if(index == 0) {
      url = "https://anvizent.com/"
    }
    else if(index == 1) {
      url = "https://dominicsystems.com/"
    }
    else {
      url = "https://www.umbrellaconsulting.ca/"
    }

    return <a href={url}><Img key={index} fluid={img.childImageSharp.fluid} /></a>;
  });
};

export default Items;
