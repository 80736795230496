import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect } from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';
import { makeStyles, Grid, Typography, Button, Container, Box } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import CarouselItems from '../../components/carouselItemsLoader';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    backgroundColor: '#191919',
  },
  mainContainer: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 0, 10),
    },
  },
  arrowLeft: {
    fill: '#ffffff',
  },
  carouselItem: {
    padding: theme.spacing(0, 5),
  },
  slider: {
    marginTop: theme.spacing(8),
  },
}));

const SampleNextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <ArrowRightIcon
      className={className}
      style={{ ...style, display: 'block', color: '#ffffff', fontSize: '3rem' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <ArrowLeftIcon
      className={className}
      style={{ ...style, display: 'block', color: '#ffffff', fontSize: '3rem' }}
      onClick={onClick}
    />
  );
};

export default function SectionB() {
  const classes = useStyles();
  return (
    <section className={classes.rootContainer}>
      <Container>
        <div className={classes.mainContainer}>
          <Typography variant="h1" align="center">
            <Box color="#ffffff">Trusted by Businesses Nationally</Box>
          </Typography>
          <Slider
            slidesToShow={3}
            slidesToScroll={1}
            infinite
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            className={classes.slider}
            responsive={[
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {CarouselItems().map((item, index) => (
              <div key={index} className={clsx(classes.carouselItem)}>
                {item}
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </section>
  );
}
