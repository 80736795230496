import React from 'react';
import {
  createMuiTheme,
  responsiveFontSizes,
  makeStyles,
  ThemeProvider,
  CssBaseline,
  Typography,
  Container,
  Grid,
  Divider,
  Link,
} from '@material-ui/core';

import 'typeface-montserrat';
import 'helvatica-neue-lt';

import SEO from '../components/seo';
import SectionA from '../features/index/sectionA';
import SectionB from '../features/index/sectionB';
import SectionC from '../features/index/sectionC';
import SectionD from '../features/index/sectionD';
import SectionE from '../features/index/sectionE';
import SectionF from '../features/index/sectionF';
import SectionG from '../features/index/sectionG';
import Footer from '../features/footer';

let theme = createMuiTheme({
  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#e67400',
    },
    secondary: {
      main: '#44b0db',
    },
    text: {
      secondary: '#8e8e8e',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Helvetica-Neue',
    h1: {
      fontSize: 26,
      fontWeight: 500,
    },
    h2: {
      fontSize: 23,
      fontWeight: 700,
    },
    h3: {
      fontSize: 22,
      fontWeight: 500,
    },
    h4: {
      fontSize: 22,
      fontWeight: 400,
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
    },
    h6: {
      fontSize: 13,
      fontWeight: 300,
    },
    button: {
      fontSize: 15,
      fontWeight: 600,
      textTransform: 'none',
    },
    body1: {
      fontSize: 40,
      fontWeight: 600,
      lineHeight: 1,
    },
    body2: {
      fontSize: 33,
      fontWeight: 600,
      lineHeight: 1,
    },
    caption: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1.15,
      color: '#ffffff',
      textAlign: "left",
    },
    subtitle1: {
      fontSize: 27,
      fontWeight: 500,
      lineHeight: 1,
    },
    overline: {
      fontSize: 77,
      fontWeight: 700,
      lineHeight: 1,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 23,
      },
    },
    MuiContainer: {
      root: {
        // '@media (min-width: 0px)': {
        //   paddingLeft: 32,
        //   paddingRight: 32,
        // },
        paddingLeft: 32,
        paddingRight: 32,
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: '1.5rem',
      },
    },
    MuiDivider: {
      root: {
        height: 4,
      },
    },
    SlickSlide: {
      display: 'inline-block',
      verticalAlign: 'middle',
      float: 'none',
    },
  },
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles(themes => ({
  root: {
    padding: themes.spacing(4),
    height: '100vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const IndexPage = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <SEO title="Home" id="top" />
      <CssBaseline />
      <SectionA />
      <SectionC />
      <SectionD />
      <SectionB />
      <SectionG />
      <Footer />
    </ThemeProvider>
  );
};

export default IndexPage;
