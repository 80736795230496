/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles, makeStyles, Paper, Grid } from '@material-ui/core';

const StyledPaper = withStyles(theme => ({
  root: {
    borderRadius: 38,
    display: 'flex',
    padding: theme.spacing(2),
  },
}))(Paper);

const useStyles = makeStyles(theme => ({
  imageSection: {
    flexGrow: 1.5,
    minWidth: 110,
  },
  textSection: {
    marginLeft: 18,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    marginTop: 'auto',
    marginLeft: -6,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function Card(props) {
  const { children } = props;
  const classes = useStyles();

  return (
    <StyledPaper elevation={3}>
      <div className={classes.imageSection}>{children[0]}</div>
      <div className={classes.textSection}>
        {children[1]}
        {children[2]}
        <div className={classes.buttonWrapper}>{children[3]}</div>
      </div>
    </StyledPaper>
  );
}
