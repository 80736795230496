import React from 'react';
import { makeStyles, Grid, Typography, Container } from '@material-ui/core';

import Icon1 from '../../assets/icons/icon-1.inline.svg';
import Icon2 from '../../assets/icons/icon-2.inline.svg';
import Icon3 from '../../assets/icons/icon-3.inline.svg';
import Icon4 from '../../assets/icons/icon-4.inline.svg';

const useStyles = makeStyles(theme => ({
  rootContainer: {},
  mainContainer: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 0, 5),
    },
  },
  textSection: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  cardWrapper: {
    padding: theme.spacing(5, 0),
  },

  textCard: {
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: 532,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
    },
    '& > *': {
      marginBottom: theme.spacing(3),
    },
  },
}));

export default function SectionB() {
  const classes = useStyles();
  return (
    <section className={classes.rootContainer} id="services">
      <Container>
        <div className={classes.mainContainer}>
          <div className={classes.textSection}>
            <Typography variant="h1" color="textSecondary" gutterBottom>
              Why CandleStream?
            </Typography>
            <Typography variant="body2" gutterBottom>
              We create and use superior tools to make the most of your data.
            </Typography>
          </div>
          <div className={classes.cardWrapper}>
            <Grid container direction="row" spacing={5}>
              <Grid item xs={12} md={6}>
                <div className={classes.textCard}>
                  <Icon1 />
                  <Typography variant="h1">Business Intelligence</Typography>
                  <Typography variant="h4">
                  We have extensive experience with Power BI and the automated Anvizent Analytics solution for
                  SYSPRO. Our solutions have transformed operational business reporting. 
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.textCard}>
                  <Icon2 />
                  <Typography variant="h1">Custom Programming</Typography>
                  <Typography variant="h4">
                    We implement a wide-range of custom applications for ERP systems amd stand alone software. Our
                    expertise in .Net and SQL server has enhanced workflows for dozens of companies.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.textCard}>
                  <Icon3 />
                  <Typography variant="h1">Reporting</Typography>
                  <Typography variant="h4">
                    We are experts in SSRS and Crystal Reports and have written thousands of reports for many different
                    data sources. Clear, concise reporting is difficult, and our team excells at it.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.textCard}>
                  <Icon4 />
                  <Typography variant="h1">Enterprise Software</Typography>
                  <Typography variant="h4">
                  We see businesses frequently not using their ERP software to its full potential. We have decades of
                  experience with SYSPRO and other ERP and CRM systems. Our team are experts in developing custom
                  panes, workflows, and .Net applications to fully leverage your ERP investment. 
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </section>
  );
}
