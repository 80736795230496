import React from 'react';
import { makeStyles, Grid, Typography, Button, Container } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import backgroundImage from '../../assets/images/sectionB-background-Image-1.svg';
import backgroundImageMobile from '../../assets/images/sectionB-background-Image-2.svg';
import ImageLoader from '../../components/image';

import Card from '../../components/card';

function getModalStyle() {
  return {
      top: `30%`,
      left: `50%`,
      transform: `translate(-50%, -30%)`,
  };
}

const useStyles = makeStyles(theme => ({
  rootContainer: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${backgroundImageMobile})`,
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(15),
    },
  },
  mainContainer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(17, 0, 10),
    },
  },
  textSection: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  cardWrapper: {
    padding: theme.spacing(5, 0),
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      width: 90,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    minHeight: '200px',
    maxHeight: '80%',
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    borderRadius: 20,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4, 4),
},
}));

const persons = [
  {
    image: 'personImage1',
    name: 'Doug Bird',
    position: 'Sr. Developer and Owner',
    bio: `Doug Bird has over 26 years of experience in the IT world; with most of it in the heavy equipment manufacturing sector. Prior to starting CandleStream Systems Doug worked as an IT Manager and Sr. Business Analyst with large manufacturing firms.`,
  },
  {
    image: 'personImage2',
    name: 'Bob DeRidder',
    position: 'VP - Business Development',
    bio: `Bob is the consummate professional and a seasoned business leader. Having his own greatly successful practice for several years, he offers a wealth of SYSPRO knowledge, entrepreneurial spirit, and mentoring to our team.`,
  },
  {
    image: 'personImage3',
    name: 'Johann Krieger',
    position: 'Developer',
    bio: `Johann brings a wealth of programming and math expertice to our team. He has many years of experience with SQL Server: stored procedures, functions, and SSRS. And also Crystal reports, C#, and VB .Net.`,
  },
  {
    image: 'personImage4',
    name: 'Jason Fink',
    position: 'Sr. Developer',
    bio: `Jason is an extraordinary Microsoft .Net developer and programmer. He has extensive experience with C#, SQL, SSRS, and ERP systems and brings many years of experience to our team. `,
  },
  {
    image: 'personImage5',
    name: 'Patrick Klitgaard',
    position: 'Jr. Systems Analyst',
    bio: `Patrick is a graduate of Okanagan College in Kelowna. He brings a wealth of programming skills and infectious enthusiasm to our team! `,
  },
];

export default function SectionB() {
  const classes = useStyles();
  
  return (
    <section className={classes.rootContainer}>
      <Container>
        <div className={classes.mainContainer}>
          <div className={classes.textSection}>
            <Typography variant="h1" color="textSecondary" gutterBottom>
              Our Team
            </Typography>
            <Typography variant="body2">
              We leverage decades of experience to create solutions for your business.
            </Typography>
          </div>
          <div className={classes.cardWrapper}>
            <Grid container direction="row" spacing={5}>
              {persons.map((person, index) => {
                var [open, setOpen] = React.useState(false);
                const [modalStyle] = React.useState(getModalStyle);

                var handleOpen = () => {
                    setOpen(true);
                };
            
                var handleClose = () => {
                    setOpen(false);
                };

                return (
                  <Grid item xs={12} sm={6} md={4} key={person.name}>
                    <Card>
                      <ImageLoader name={person.image} className={classes.image} />
                      <Typography variant="h2">{person.name}</Typography>
                      <Typography variant="h6">{person.position}</Typography>
                      <Button color={index % 2 ? 'secondary' : 'primary'} onClick={handleOpen} endIcon={<ArrowForwardIcon />}>
                        Read More
                      </Button>
                    </Card>
                    <Modal
                        aria-labelledby={index + "-transition-modal-title"}
                        aria-describedby={index + "-transition-modal-description"}
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 350,
                        }}
                        >
                        <Fade in={open}>
                          <div style={modalStyle} className={classes.paper}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={2}>
                                <ImageLoader name={person.image} className={classes.image} />
                              </Grid>
                              <Grid item xs={12} md={8} style={{ paddingTop: 25 }}>
                                <Typography variant="h1">{person.name}</Typography>
                                <Typography variant="h3">{person.position}</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h4">{person.bio}</Typography>
                              </Grid>
                            </Grid>
                          </div>
                        </Fade>
                      </Modal>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </Container>
    </section>
  );
}
