import React from 'react';
import { makeStyles, Grid, Typography, Button, Container, Box } from '@material-ui/core';

//import Image from '../assets/images/sectionC-Image.inline.svg';
//import Image from '../assets/icons/icon-1.inline.svg';
import Image from '../../assets/icons/icon-bi.inline.svg';
//import Image from '../assets/icons/bi-icon-inline.svg';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    backgroundColor: '#191919',
  },
  mainContainer: {
    padding: theme.spacing(15, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0),
    },
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  leftContainer: {
    '& > *': {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  image: {
    height: 307,
    width: 404,
    [theme.breakpoints.down('sm')]: {
      height: 244,
      width: 321,
    },
  },
}));

export default function SectionB() {
  const classes = useStyles();
  return (
    <section className={classes.rootContainer} id="bi">
      <Container>
        <Grid container className={classes.mainContainer} alignItems="center" spacing={5}>
          <Grid item xs={12} md={5}>
            <div className={classes.rightContainer}>
              <Image className={classes.image} />
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            <div className={classes.leftContainer}>
              <Typography variant="caption" component="div">
              Business Intelligence will accelerate your company’s growth and efficiency, which is
              why we have developed a portfolio of reporting solutions for your SYSPRO environment.
              From Power BI reports, to an automated Anvizent solution, to complex data warehousing,
              we have the expertise to fit your requirements.
              </Typography>
            </div>
            <Button variant="contained" color="primary" size="large" className={classes.button} href="https://portal.candlestream.com">
              <Box color="#ffffff">Check out our Demos</Box>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
