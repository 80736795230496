import React from 'react';
import { makeStyles, Grid, Typography, Button, Container, Box, Divider, Hidden } from '@material-ui/core';

import Icon from '../../assets/icons/icon-5.inline.svg';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    backgroundColor: '#f0f0f0',
  },
  mainContainer: {
    padding: theme.spacing(12, 0),
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(12, 0),
    // },
  },
  rightContainer: {
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(7),
  },
  leftContainer: {},
  iconWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: 16,
    maxWidth: 210,
    [theme.breakpoints.up('sm')]: {
      marginTop: 32,
      marginRight: 16,
    }
  },
  textSection: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    '& :nth-child(1)': {
      width: 120,
    },
    '& :nth-child(2)': {
      marginLeft: theme.spacing(6),
    },
  },
}));

export default function SectionA() {
  const classes = useStyles();

  function redirectToBlog() {
    location.href = "https://candlestream.com/blog";
  }
  function redirectToSignup() {
    location.href = "https://candlestream.com/blog";
  }

  return (
    <section className={classes.rootContainer}>
      <Container>
        <Grid container className={classes.mainContainer} spacing={5}>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.leftContainer}>
              <div className={classes.iconWrapper}>
                <Icon />
              </div>
              <Typography variant="body1" gutterBottom>
                The latest from our blog
              </Typography>
              <Typography variant="h4">Learn about the importance of BI and leveraging data to inform your business decisions.</Typography>
              <Hidden mdUp>
                <Button variant="contained" color="primary" fullWidth className={classes.button}>
                  <Box color="#ffffff">View Posts</Box>
                </Button>
                <Button variant="contained" color="secondary" fullWidth className={classes.button}>
                  <Box color="#ffffff">Sign Up for Updates</Box>
                </Button>
              </Hidden>
            </div>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} sm={6} md={8}>
              <div className={classes.rightContainer}>
                <a className={classes.textSection} href="https://candlestream.com/blog">
                  <Typography variant="h3">JULY 3</Typography>
                  <Typography variant="subtitle1">How effective data analysis can transform your business.</Typography>
                </a>
                <Divider />
                <div className={classes.textSection}>
                  <Typography variant="h3">JUNE 21</Typography>
                  <Typography variant="subtitle1">
                    The latest in data tech. Live updates from some conference..
                  </Typography>
                </div>
                <Divider />
                <div className={classes.textSection}>
                  <Typography variant="h3">JUNE 21</Typography>
                  <Typography variant="subtitle1">A case study: a case study; Case Study: by Doug Bird.</Typography>
                </div>
                <div className={classes.iconWrapper}>
                  <Button variant="contained" color="primary" fullWidth className={classes.button} onClick={redirectToBlog}>
                    <Box color="#ffffff">View Posts</Box>
                  </Button>
                  <Button variant="contained" color="secondary" fullWidth className={classes.button} onClick={redirectToSignup}>
                    <Box color="#ffffff">Sign Up for Updates</Box>
                  </Button>
                </div>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </section>
  );
}
