import React from 'react';
import { makeStyles, Link, Grid, Typography, Button, Container } from '@material-ui/core';

import Header from '../header';
import backgroundImage from '../../assets/images/sectionA-background-Image-1.svg';
import backgroundImageMobile from '../../assets/images/sectionA-background-Image-2.svg';
import Image from '../../assets/images/sectionA-Image.inline.svg';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${backgroundImageMobile})`,
      backgroundSize: 'auto',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(15),
    },
  },
  mainContainer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12, 0),
    },
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
      minHeight: 300,
      maxWidth: 600,
    },
  },
  button: {
    maxWidth: 120,
  },
}));

export default function SectionA() {
  const classes = useStyles();
  return (
    <section className={classes.rootContainer}>
      <Header />
      <Container>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12} md={6}>
            <div className={classes.leftContainer}>
              <Typography variant="body1" gutterBottom>
                CandleStream helps you empower your data
              </Typography>
              <Typography variant="h4" gutterBottom>
                Founded with a vision of making substantial improvements to the way companies interact
                with their data, CandleStream has been building business solutions since 2012.
              </Typography>
              <Link color="inherit" href="#contact">
                <Button variant="contained" color="primary" fullWidth className={classes.button}>
                  Let's Talk
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.rightContainer}>
              <Image />
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
