import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Image = props => {
  const { name } = props;

  const data = useStaticQuery(graphql`
    query {
      personImage1: file(relativePath: { eq: "person-image-1.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 110) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      personImage2: file(relativePath: { eq: "person-image-2.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 110) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      personImage3: file(relativePath: { eq: "person-image-3.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 110) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      personImage4: file(relativePath: { eq: "person-image-4.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 110) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      personImage5: file(relativePath: { eq: "person-image-5.png" }) {
        childImageSharp {
          fluid(pngQuality: 100, maxWidth: 110) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return <Img fluid={data[name].childImageSharp.fluid} {...props} />;
};

export default Image;
